import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import logo from '../../images/blossom-logo-social.png'
function SEO({ description, lang, meta, title, image: metaImage, pathname, cssClass}) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						keywords
						siteUrl
					}
				}
			}
		`
	)

	const metaDescription = description || site.siteMetadata.description
	const metaTitle = `${title} | ${site.siteMetadata.title}`
	const image =
		metaImage && metaImage.src
			? `${site.siteMetadata.siteUrl}${metaImage.src}`
			: `${site.siteMetadata.siteUrl}${logo}`
	const imageHeight =
		metaImage && metaImage.height
			? metaImage.height
			: 304
	const imageWidth =
		metaImage && metaImage.width
			? metaImage.width
			: 944
	const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			bodyAttributes={{
				class: cssClass
			}}
			title={title}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			link={
				canonical ? [
					{
						rel: "canonical",
						href: canonical,
					},
				] : []
			}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					name: "keywords",
					content: site.siteMetadata.keywords.join(","),
				},
				{
					property: `og:title`,
					content: metaTitle,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: metaTitle,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				{
					property: "og:image",
					content: image,
				},
				{
					property: "og:image:width",
					content: imageWidth,
				},
				{
					property: "og:image:height",
					content: imageHeight,
				},
				{
					name: "twitter:card",
					content: "summary_large_image",
				},
			]
				.concat(meta)}
		/>
	)
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
	image: PropTypes.shape({
		src: PropTypes.string.isRequired,
		height: PropTypes.number.isRequired,
		width: PropTypes.number.isRequired,
	}),
	pathname: PropTypes.string,
}

export default SEO